.container_main {
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 480px) {
    .container_main {
        flex-wrap: wrap;
    }
}

.container{
    width: 120px;
    height: 25px;
    font-size: 14px;
    text-align: center;
    border-radius: 8.489px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    cursor: pointer;
    font-weight: 300;
    padding-top: 4px;
    padding-bottom: 5px;
    margin-bottom: 25px;
}


.container:nth-child(even) {
    margin-left: 8px;
    margin-right: 8px;
}

.container_active {
    width: 120px;
    height: 25px;
    text-align: center;
    font-size: 14px;
    border-radius: 8.489px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 0.50), 0px 0px 4px 0px #FFF, 0px 0px 4px 0px rgba(255, 255, 255, 0.50);
    font-weight: 300;
    margin-bottom: 25px;
    padding-top: 4px;
    padding-bottom: 5px;
}
.container_active:nth-child(even) {
    margin-left: 8px;
    margin-right: 8px;
}

@media (max-width: 480px) {
    .container:nth-child(even) {
        margin-left: 8px;
    }

    .container_active:nth-child(even) {
        margin-left: 8px;
    }
    .container {
        width: 109px;
    }

    .container_active {
        width: 109px;
    }
}