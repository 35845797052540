.text {
    margin: 25px auto;
    text-align: center;
    font-size: 12px;
}

.image_container {
    width: 269px;
    margin: 0 auto;
}

.image {
    width: 269px;
}


.list {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 281px;
    height: 53px;
    margin: 0 auto;
    z-index: 10;
}