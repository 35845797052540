.item_title {
    display: flex;
    font-size: 14px;
    align-items: center;
}

.active {
    display: flex;
    height: 44px;
    align-items: center;
}

.image {
    margin-right: 29px;;
    width: 6px;
    height: 12px; 
    animation: rotate_second 0.7s;  
}
.image_active {
    animation: rotate 0.7s;
    transform: rotate(90deg);
}

.item_paragraph {
    font-weight: 300;
    font-size: 14px;
    margin-left: 37px;
    margin-bottom: 15px;
    animation: shadow 0.7s;
}
@keyframes rotate_second {
    from {
        transform: rotate(90deg);
    }
    to {
        transform: rotate(0);
    }
}

@keyframes rotate {
    from {
        transform: rotate(0);
    }
    to {
        transform: rotate(90deg);
    }
}

@keyframes shadow {
    from {
        color: black;
        margin-left: 45px;
    }
    to {
        color: white;
        margin-left: 37px;
    }
}