.container {
    display: flex;
    text-align: center;
    align-items: center;
    flex-grow: 1;
    cursor: pointer;
}

@media (max-width: 480px) {
    .container {
        background-color: #080808;
        margin: 0 1px;
    }
}

.title {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    color: #A1A0A0;
    font-size: 14px;
    flex-grow: 1;
    font-weight: 400;
}

@media (max-width: 480px) {
    .title {
        font-size: 14px;
    }

    .title_active {
        font-size: 16px;
    }
}

.title_active {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 17px;
    flex-grow: 1;
    color: white;
    font-weight: 400;
}