.container_header {
    display: flex;
    margin: 0 auto;
    align-items: center;
    justify-content: space-between;
    width: 149px;
    margin-bottom: 12px;

}

.header_title {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 25px;
    font-weight: 600;
}