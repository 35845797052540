.wrapper {
  position: fixed;
  z-index: 100;
  /* min-height: 100vh; */
  background-color: rgba(0, 0, 0, 0.7);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  height: fit-content;
  padding: 22px;
  display: flex;
  flex-direction: column;
  gap: 17px;
  border-radius: 10px;
  border: 1px solid #727272;
  background: #2a2a2a;
  max-width: 343px;
  margin: 20px auto;
  max-height: 100vh;
}

.container_m {
  text-align: center;
  max-width: 343px;
  height: fit-content;
  padding: 22px;
  display: flex;
  flex-direction: column;
  gap: 17px;
  margin: 20px auto;
  max-height: 100vh;
}

.title {
  text-shadow: 0px 0px 4px rgba(246, 73, 48, 0.7);
  font-family: "Tektur", "Times New Roman", Times, cursive;
  font-size: 33px;
  font-weight: 600;
  max-width: 300px;
}

.description {
  font-size: 14px;
  font-weight: 300;
  max-width: 300px;
}

.button {
  width: fit-content;
  font-size: 14px;
  font-weight: 300;
  border-radius: 5.611px;
  border: 0.573px solid #727272;
  background: rgba(42, 42, 42, 0.7);
  display: flex;
  padding: 4px 29px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  cursor: pointer;
}

.button:hover {
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5), 0px 0px 4px 0px #fff,
    0px 0px 4px 0px rgba(255, 255, 255, 0.5);
}

.button_m {
  width: fit-content;
  font-size: 14px;
  font-weight: 300;
  border-radius: 5.611px;
  border: 0.573px solid #727272;
  background: rgba(42, 42, 42, 0.7);
  display: flex;
  padding: 4px 29px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  text-decoration: none;
  margin: 0 auto;
  cursor: pointer;
}

.button_m:hover {
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5), 0px 0px 4px 0px #fff,
    0px 0px 4px 0px rgba(255, 255, 255, 0.5);
}
