.container {
    background: rgba(26, 26, 26, 0.7);
    backdrop-filter: blur(5px);
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 39px;
    padding-top: 60px;
}

@media (max-width: 480px) {
    .container {
        margin: 0;
        padding-top: 105px;
    }
}

.winners {
    margin: 40px 20px;
}

@media (max-width: 480px) {
    .winners {
        margin: 20px;
    }
}

.faffle {
    margin: 40px auto;
}

@media (max-width: 480px) {
    .faffle {
        margin: 20px auto;
    }
}


.container_title {
    text-align: center;
    width: 100%;
}

.title {
    font-family: "Tektur", "Times New Roman", Times, cursive;
    font-size: 33px;
    font-weight: 600;
}

.text {
    font-size: 14px;
    font-weight: 400;
    margin-top: 21px;
    margin-bottom: 35px;
}

.container_content {
    display: flex;
    flex-grow: 1;
    text-align: center;
    justify-content: center;
}

.card_image_1 {
    max-width: 360px;
    position: relative;
    top: -4px;
}

.card_image_2 {
    max-width: 364px;
    position: relative;
    left: 4px;
    top: 4px;
}

.card_image_0 {
    position: relative;
    left: -80px;
    max-width: 432px;
}

@media (max-width: 480px) {
    .card_image_0 {
        max-width: 384px;
        left: -72px;
        top: -10px;
    }

    .card_image_1 {
        max-width: 300px;
        left: 6px;
    }

    .card_image_2 {
        max-width: 300px;
        left: 12px;
    }
}

@media (max-width: 330px) {
    .card_image_0 {
        left: -100px;
    }
}

.box {
    width: 100%;
    height: 82px;
}

@media (max-width: 480px) {
    .box {
        height: 40px;
    }
}

.footer {
    display: flex;
    margin-top: 5px;
    justify-content: flex-end;
    padding-bottom: 16px;
    padding-right: 22px;
    text-align: end;
}

.footer_text {
    cursor: pointer;
    text-decoration: underline;
    color: #727272;
    font-size: 14px;
    font-weight: 400;
    margin-left: 24px;
}

@media (max-width: 480px) {
    .footer {
        margin-top: 10px;
        justify-content: center;
    }

    .footer_text {
        color: #fff;
    }
}
