.container {
    position: relative;
    z-index: 10;
}

.container_in {
    position: relative;
    width: 30px;
    cursor: pointer;
}

.before {
    position: relative;
    width: 40px;
    cursor: pointer;
    left: -2px;
}

.active {
    position: relative;
    width: 48px;
    left: -4px;

}
.container_last {
    position: relative;
    width: 50px;
    cursor: pointer;
}

.active_default {
    position: relative;
    width: 40px;
}

.container_before_dis {
    position: relative;
    width: 30px;
}

.active_default_before {
    position: relative;
    width: 40px;
}

.last_current_dis {
    position: relative;
    width: 50px;
}

/* 
.box {
    position: relative;
    width: 23px; 
    height: 22px;
    transform: skew(25deg);
    background: linear-gradient(180deg, #F1725F 0%, #B52915 100%);
    border: 1px solid white;
    border-image: linear-gradient(180deg, rgba(255, 255, 255, 1), #B52915);
    border-image-slice: 1;
    border-radius: 3px;
    z-index: 1;
} */

.svg_active {
    position: relative;
}

.svg_before {
    position: relative;
    cursor: pointer;
}

.day {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 15px;
    font-weight: 600;
    position: absolute;
    top: 3px;
    left: 13px;
    background-color: rgba(91, 129, 140, 1);
    color: transparent;
    text-shadow: 0px 0px 1px black;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    cursor: pointer;
}

.day_active {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    position: absolute;
    font-weight: 600;
    font-size: 20px;
    top: 11px;
    left: 25px;
    background-color: rgba(255, 70, 85, 1);
    color: transparent;
    text-shadow: 0px 0px 1px rgba(255,255,255,1);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.day_before {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    position: absolute;
    font-weight: 600;
    top: 3px;
    left: 13px;
    background-color: rgba(255, 70, 85, 1);
    color: transparent;
    text-shadow: 0px 0px 1px rgba(255,255,255,1);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    cursor: pointer;
}

.day_current_dis {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    position: absolute;
    font-weight: 600;
    top: 9px;
    left: 18px;
    background-color: rgba(255, 70, 85, 1);
    color: transparent;
    text-shadow: 0px 0px 1px rgba(255,255,255,1);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    cursor: pointer;
}

.day_last {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 15px;
    font-weight: 600;

    position: absolute;
    top: 3px;
    left: 30px;
    background-color: rgba(91, 129, 140, 1);
    color: transparent;
    text-shadow: 0px 0px 1px black;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;  
    cursor: pointer;  
}

.day_last_active {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    position: absolute;
    font-weight: 600;
    font-size: 20px;
    top: 11px;
    left: 45px;
    background-color: rgba(255, 70, 85, 1);
    color: transparent;
    text-shadow: 0px 0px 1px rgba(255,255,255,1);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;   
}

.active_default_day {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 20px;
    font-weight: 600;
    position: absolute;
    top: 4px;
    left: 15px;
    background-color: rgba(91, 129, 140, 1);
    color: transparent;
    text-shadow: 0px 0px 1px black;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    cursor: pointer;
}

.active_last_day {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-weight: 600;
    position: absolute;
    font-size: 20px;
    top: 6px;
    left: 40px;
    background-color: rgba(91, 129, 140, 1);
    color: transparent;
    text-shadow: 0px 0px 1px black;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;  
    cursor: pointer; 
}

.active_default_day_before {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    position: absolute;
    font-weight: 600;
    font-size: 20px;
    top: 4px;
    left: 18px;
    background-color: rgba(255, 70, 85, 1);
    color: transparent;
    text-shadow: 0px 0px 1px rgba(255,255,255,1);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.last_current_dis_day {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    position: absolute;
    font-weight: 600;
    font-size: 15px;
    top: 11px;
    left: 38px;
    background-color: rgba(255, 70, 85, 1);
    color: transparent;
    text-shadow: 0px 0px 1px rgba(255,255,255,1);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text; 
}