* {
    box-sizing: border-box;
    /* margin: 0; */
    font-family: "Rubik", Arial, Helvetica, sans-serif;
    font-weight: 400;
    color: white;
    user-select: none;
}

html {
    height: 100%;
}

body {
    height: 100%;
    background-color: rgba(42, 42, 42, 1);
}

.vkuiPanelHeader:before,
.vkuiPanelHeader__in {
    box-sizing: unset !important;
}

.vkuiPanel .vkuiPanel__in,
.vkuiPanel:before {
    background-color: rgba(255, 255, 255, 0) !important;
}

.vkuiFixedLayout {
    z-index: 150 !important;
}

.vkuiScreenSpinner__container {
    background-color: unset;
}

button {
    border-radius: 6px;
    border: 0.6px solid #727272;
    background: rgba(42, 42, 42, 0.7);
    color: white;
    font-size: 14px;
    cursor: pointer;
}

img {
    object-fit: cover;
}

button:hover {
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5), 0px 0px 4px 0px #fff,
        0px 0px 4px 0px rgba(255, 255, 255, 0.5);
}

.container_title {
    width: 100%;
    text-align: center;
    text-transform: uppercase;
    background-color: rgb(13, 0, 45);
    color: white;
}

.container_content {
    width: 100%;
    height: 100vh;
    color: white;
}

.highlight_value {
    font-family: "Tektur", "Times New Roman", Times, cursive;
    color: #ffc700;
    font-size: 14px;
    height: 11px;
    width: 15px;
    font-weight: 500;
}

.value {
    font-family: "Tektur", "Times New Roman", Times, cursive;
}

.highlighted {
    color: #FFC700;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 14px;
    font-weight: 500;
}

.alert_first {
    width: 300px;
    font-size: 10px;
    font-weight: 300;
}

.alert_regular {
    font-size: 10px;
    width: 200px;
    font-weight: 300;
}

/*     font-family: 'Tektur', 'Times New Roman', Times, cursive; */
