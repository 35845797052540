.container {
    background-color: #080808;
    display: flex;
    flex-grow: 1;
    height: 40px;
    margin-left: 15px;
    margin-right: 15px;
    position: fixed;
    left: -15px;
    width: 100%;
    z-index: 150;
}
@media (max-width: 480px) {
    .container {
        height: 65px;
        position: fixed;
        width: 100%;
        z-index: 150;
        margin: 0;
        left: unset;
    }
}