.none {
  animation: opacity ease 3s;
  opasity: 0;
  display: none;
  position: relative;
}

.none_done {
  display: none;
}

.container {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  width: 154px;
  min-height: 180px;
  height: calc(100% - 16px);
  text-align: center;
  border-radius: 10px;
  border: 1px solid #727272;
  background: rgba(42, 42, 42, 0.7);
  box-shadow: 0px 0px 7px 0px rgba(255, 255, 255, 0.5), 0px 0px 7px 0px #fff,
    0px 0px 7px 0px rgba(255, 255, 255, 0.5);
  backdrop-filter: blur(5px);
  padding: 17px 10px 15px;
  margin: 8px;
}

@media (max-width: 390px) {
  .container {
    width: calc(100vw - 70px);
    min-height: 120px;
    display: block;
    margin: 20px auto 0;
    padding: 17px 19px 0;
  }
}

.title {
  text-align: center;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Tektur", "Times New Roman", Times, cursive;
  font-size: 14px;
  font-weight: 500;
  line-height: 13px;
  max-width: 154px;
}

.text {
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  margin-top: 12px;
}

@media (max-width: 390px) {
  .title {
    max-width: 100%;
    text-align: left;
    font-size: 16px;
  }

  .text {
    max-width: 100%;
    text-align: left;
    font-size: 12px;
  }
}

.footer {
  margin-top: 5px;
}

@media (max-width: 390px) {
  .footer {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
  }
}

.cost {
  justify-content: center;
  display: flex;
  font-size: 14px;
  font-family: "Tektur", "Times New Roman", Times, cursive;
}

.value {
  color: #ffc700;
  margin-right: 5px;
  margin-left: 1px;
  font-size: inherit;
  font-family: inherit;
  font-weight: inherit;
}

.span {
  color: #727272;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 10px;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffc700;
  text-decoration: none;
  font-size: 13px;
  font-weight: 300;
  border-radius: 6px;
  border: 0.6px solid #727272;
  background: rgba(42, 42, 42, 0.7);
  cursor: pointer;
  padding: 3px 7px;
}
.button:hover {
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5), 0px 0px 4px 0px #fff,
    0px 0px 4px 0px rgba(255, 255, 255, 0.5);
}

.button_check {
  border-radius: 5.815px;
  border: 0.6px solid #727272;
  background: rgba(42, 42, 42, 0.7);
  font-size: 13px;
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 3px 7px;
}

.button_check:hover {
  box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.5), 0px 0px 4px 0px #fff,
    0px 0px 4px 0px rgba(255, 255, 255, 0.5);
}

.checked {
  color: #727272;
  font-size: 13px;
  font-weight: 400;
}

@media (max-width: 390px) {
  .cost {
    margin-right: 10px;
  }

  .span {
    margin-right: 20px;
    margin-bottom: 0;
  }

  .button {
    width: 112px;
    height: 24px;
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .button_check {
    width: 112px;
    height: 24px;
    color: #fff;
    font-size: 12px;
    font-weight: 300;
  }

  .checked {
    width: 112px;
    height: 24px;
    color: #727272;
    font-size: 12.675px;
    font-weight: 400;
    display: flex;
    align-items: center;
  }
}

.alert_check {
  position: relative;
  top: -15px;
  left: -75%;
}

@media (max-width: 390px) {
  .alert_check {
    left: -100px;
  }
}

.alert {
  width: 170px;
}

.link_help {
  text-decoration: underline;
  cursor: pointer;
}

/* disabled */

.container_dis {
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  justify-content: center;
  width: 154px;
  min-height: 180px;
  height: calc(100% - 16px);
  text-align: center;
  border-radius: 10px;
  border: 1px solid #727272;
  background: rgba(42, 42, 42, 0.7);
  backdrop-filter: blur(5px);
  padding: 17px 10px 15px;
  margin: 20px 6px;
}

@media (max-width: 390px) {
  .container_dis {
    width: calc(100vw - 75px);
    min-height: 120px;
    margin: 20px 30px 0;
    justify-content: flex-start;
  }
}

.title_dis {
  text-align: center;
  color: #727272;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  font-family: "Tektur", "Times New Roman", Times, cursive;
  font-size: 12px;
  font-weight: 500;
  line-height: 13px;
}

.text_dis {
  color: #727272;
  text-align: center;
  font-size: 12px;
  font-weight: 300;
  margin-top: 12px;
}

@media (max-width: 390px) {
  .title_dis {
    width: 100%;
    text-align: left;
    font-size: 16px;
  }

  .text_dis {
    width: 100%;
    text-align: left;
    font-size: 13px;
  }
}

.button_dis {
  color: #727272;
  font-size: 13px;
  font-weight: 300;
  border-radius: 5.815px;
  border: 0.685px solid #727272;
  background: rgba(42, 42, 42, 0.7);
  cursor: unset;
}

@media (max-width: 290px) {
  .button_dis {
    font-size: 12px;
    padding-top: 5px;
    padding-bottom: 5px;
    width: 112px;
    height: 24px;
  }
}

.button_dis:hover {
  box-shadow: none;
}

@keyframes opacity {
  0% {
    opacity: 1;
    display: block;
  }
  100% {
    opacity: 0;
    display: none;
  }
}
