.container_first {
  background-image: url('https://bp-assets.dnzg.ru/app1.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding-bottom: 110px;
  position: relative;
}

.container {
  /* min-height: 100vh; */
  height: auto;
  position: relative;
}

.page_title {
  color: #fff;
  text-align: center;
  text-shadow: 0px 0px 5px rgba(246, 73, 48, 0.7);
  font-family: Tektur;
  font-weight: 500;
  font-size: 38px;
  margin-top: 55px;
  margin-bottom: 30px;
  z-index: 10;
}

@media (max-width: 480px) {
  .page_title {
    font-size: 23px;
    margin: 36px auto 20px;
    z-index: 10;
    position: relative;
    width: 337px;
  }
}

@media (max-width: 480px) {
  .mask {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(26, 26, 26, 0.70);
    backdrop-filter: blur(5px);
    z-index: 1;
  }
}

.container_timeline {
  position: relative;
}

.step_title {
  color: #FFF;
  text-align: center;
  font-family: 'Tektur', 'Times New Roman', Times, cursive;
  font-size: 24px;
  font-weight: 600;
  line-height: 88%; /* 21.12px */
  z-index: 20;
  margin-bottom: 12px;
}

.step_text {
  color: #FFF;
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  width: 250px;
  z-index: 20;
}

.container_text {
  display: flex;
  flex-wrap: wrap;
  max-width: 550px;
  margin: 0 auto 54px;
  justify-content: space-between;
  z-index: 20;
}

@media (max-width: 520px) {
  .step_text {
    width: 320px;
  }

  .container_text {
    max-width: 330px;
    height: auto;
  }
}

.desc_even {
  margin-top: 32px;
  z-index: 20;
}

.desc {
  margin-top: 32px;
  z-index: 20;
}

.step {
  position: relative;
  display: flex;
  align-items: center;
  height: 63px;
  width: 320px;
  margin: 0 auto;
  z-index: 20;
}

.step_even {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  height: 63px;
  width: 325px;
  margin: 0 auto;
  z-index: 20;
}

.last_step {
  position: relative;
  margin: 14px auto 44px;
  width: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 20;
}

.footer_text {
  display: flex;
  flex-direction: column;
  width: 200px;
  align-items: center;
  justify-content: center;
}

.step_rune_even {
  position: relative;
  width: 107px;
  height: 105px;
  z-index: 20;
}


.step_rune {
  position: relative;
  width: 107px;
  height: 105px;
  z-index: 20;
}


.step_cup {
  position: relative;
  height: 105px;
  z-index: 20;
}

.step_week {
  color: #fff;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 300;
}

.step_week_last {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  width: 100px;
  text-align: center;
}


.step_date {
  color: #fff;
  text-shadow: 0px 0px 4px rgba(246, 73, 48, 0.7);
  font-family: Tektur;
  font-size: 14px;
  font-weight: 500;
  width: 105px;
}

.step_date_even {
  color: #fff;
  text-shadow: 0px 0px 4px rgba(246, 73, 48, 0.7);
  font-family: Tektur;
  font-size: 14px;
  font-weight: 500;
  width: 110px;
}

.step_date_last {
  color: #fff;
  text-shadow: 0px 0px 4px rgba(246, 73, 48, 0.7);
  font-family: Tektur;
  font-size: 30px;
  font-weight: 500;
  width: 100px;
  text-align: center;
}

.text {
  font-size: 12px;
  margin-top: 3px;
  font-weight: 300;
  width: 105px;
}

.text_last {
  font-size: 12px;
  margin-top: 3px;
  font-weight: 300;
  width: 250px;
  text-align: center;
}

.step_progress_last_active {
  border-radius: 10px;
}

.continue_button {
  position: relative;
  padding: 10px 25px;
  margin: 0 auto;
  color: #fff;
  font-family: Rubik;
  font-size: 16px;
  font-weight: 300;
  z-index: 20;
}


.container_progress {
  position: relative;
}

.progress_bar {
  position: absolute;
  top: -20px;
  left: 49%;
  max-height: 402px;
  height: 402px;
  width: 10px;
  border-radius: 10px;
  border: 1px solid #727272;
  background: rgba(42, 42, 42, 0.70);
  z-index: 12;
  padding: 0;
  margin: 0 auto;
}

@media (max-width: 480px) {
  .progress_bar {
    left: 49%;
  }  
}


.progress_current {
  max-height: 100%;
  width: 9px;
  border-radius: 10px;
  background: white;
  z-index: 13;
}



.progress_bar_second {
  position: absolute;
  left: 49%;
  top: -20px;
  margin: 0 auto;
  max-height: 402px;
  height: 402px;
  width: 10px;
  border-radius: 10px;
  border: 1px solid #727272;
  background: rgba(42, 42, 42, 0.70);
  z-index: 12;
  padding: 0;
}