.container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #110c0c;
}

.close {
    position: absolute;
    top: 115px;
    right: 45px;
    cursor: pointer;
}

.card {
    width: 250px;
    margin: 30vh auto;
}