
.alert_container {
    font-size: 11px;
    padding: 10px;
    border-radius: 1px;
    background-color: rgb(42, 42, 42);
    display: flex;
    gap: 12px;
    align-items: center;
    min-width: 150px;
    width: fit-content;
    text-align: left;
  }
  
  .wrapper_image {
    width: 24px;
    height: 24px;
  }
  
  .wrapper {
    position: absolute;
    bottom: 110%;
    z-index: 10;
    opacity: 1;
    transition: opacity 0.15s ease-in-out;
  }
  
  .triangle {
    margin-left: 14px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid rgb(42, 42, 42);
  }