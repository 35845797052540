.container {
    position: relative;
    min-width: 236px;
    height: calc(100% - 16px);
    border-radius: 10px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    backdrop-filter: blur(5px);

    padding: 12px 10px 10px 21px;
    margin: 8px;
    display: flex;
    padding-bottom: 10px ;
}

.container_inner {
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
}

@media (max-width: 520px) {
    .container {
        width: calc(100vw - 70px);
        margin: 0 auto 14px;
        min-height: 123px;
        padding-bottom: 13px;
    }
}

.title {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
    z-index: 10;
    position: relative;
    max-width: 120px;
}

.description {
    font-size: 12px;
    font-weight: 300;
    width: 120px;
    z-index: 10;
    position: relative;
    margin-bottom: 15px;
}

@media (max-width: 520px) {
    .title {
        font-size: 16px;
    }
    .description {
        width: 160px;
        font-size: 12px;
    }
}


.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 205px;
}

@media (max-width: 520px) {
    .footer{
        width: calc(100%);
    }
}

.button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 12px;
    border-radius: 5.815px;
    border: 0.685px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    text-decoration: none;
    color: #FFF;
    font-size: 13px;
    font-weight: 300;
}

.button:hover {
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.50), 0px 0px 4px 0px #FFF, 0px 0px 4px 0px rgba(255, 255, 255, 0.50);
}

.button_check {
    padding: 3px 7px;
    border-radius: 5.815px;
    border: 0.6px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    font-size: 13px;
    font-weight: 300;
}

.checked {
    padding: 3px 7px;
    font-size: 13px;
    color: #727272;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
}


@media (max-width: 520px) {

    .button {
        font-size: 12px;
        width: 112px;
        height: 24px;
    }
}


/* disabled */

.container_dis {
    position: relative;
    width: 236px;
    min-height: 90px;
    height: calc(100% - 16px);
    border-radius: 10px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    backdrop-filter: blur(5px);

    padding: 12px 10px 10px 21px;
    margin: 8px;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    padding-bottom: 10px ;
}

@media (max-width: 520px) {
    .container_dis {
        width: calc(100vw - 70px);
        margin: 0 auto 14px;
        height: 123px;
        padding-bottom: 13px;
    }
}

.title_dis {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    color: #727272;
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 4px;
}

.description_dis {
    color: #727272;
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 4px;
}

@media (max-width: 520px) {
    .title_dis {
        font-size: 16px;
    }
    .description_dis {
        font-size: 12px;
    }
}


.footer_dis {
    display: flex;
    align-items: center;
    justify-content: space-between;

    width: 205px;
}

@media (max-width: 480px) {
    .footer_dis{
        width: calc(100%);
    }
}

.cost_dis {
    display: flex;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 12px;
    font-weight: 500;
}

.button_dis {
    padding: 3px 7px;
    border-radius: 5.815px;
    border: 0.685px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    color: #727272;
    font-size: 13px;
    font-weight: 400;
    cursor: default;
}

.button_dis:hover {
    box-shadow: none;
}

@media (max-width: 520px) {

    .button_dis {
        font-size: 12px;
        width: 112px;
        height: 24px;
    }
}