.container {
    background-color: rgba(9, 9, 9, 0.70);
    color: white;
    margin: 15px;
    padding-left: 30px;
    padding-top: 74px;
    height: calc(100vh - 30px)
}

@media (max-width: 480px) {
    .container{
        margin: 0;
        min-height: calc(80vh);
        padding-top: 37px;
        overflow: hidden;
    }
}

.title {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 33px;
    font-style: normal;
    font-weight: 600;
    line-height: 30px;

    margin-bottom: 39px;
}

@media (max-width: 450px) {
    .title {
        width: 300px;
        line-height: 25px;
    }
}

.title_container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 60px;
}

@media (max-width: 480px) {
    .title_container {
        padding-right: 36px;
        align-items: flex-start;
    } 
}

.cancel_container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

@media (max-width: 480px) {
    .cancel_container {
        margin-top: 25px;
    }
}
.cancel_title {
    color: #727272;
    font-size: 14px;
    font-weight: 400;
    margin-right: 11px;
}

.close_button {
    width: 13px;
    height: 13px;
}

.container_content {
    display: flex;
    justify-content: space-between;
    /* height: 425px; */
    height: calc(100% - 110px);

}

.container_content::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.container_inner {
    position: relative;
    display: flex;
    justify-content: space-between;
    height: calc(100% - 20px);
    overflow: auto;
} 

.container_inner::-webkit-scrollbar {
    width: 5px;
}

.container_inner::-webkit-scrollbar-track {
    background: #72727233;
    border-radius: 5px;
}

.container_inner::-webkit-scrollbar-thumb {
    background: #FFFFFF33;
    border-radius: 5px;
}




@media (max-width: 550px) {
    .container_content {
        height: calc(100vh - 250px);
    }
}

@media (max-width: 450px) {
    .container_content {
        height: calc(100vh - 250px);
    }
}
.container_item {
    display: flex;
    align-items: center;
    margin-bottom: 11px;
    cursor: pointer;
} 

.item {
    text-decoration: none;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 450px) {
    .item {
        font-size: 14px;
    }
}

.mini_icon {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-right: 12px;
    background-color: #D9D9D9;
}

@media (max-width: 450px) {
    .mini_icon {
        margin-right: 24px;
    }
}

.card {
    margin-top: 49px;
    text-align: center;
    width: 250px;
    text-align: center;
}


.description {
    color: #727272;
    font-size: 14px;
    font-weight: 300;
    margin-top: 9px;
}

.name {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 33px;
    font-weight: 600;
}

.sub {
    color: #FFC700;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.icon {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    background-color: #D9D9D9;
}

.button_close {
    display: none;
}

.button_sub {
    width: 120px;
    height: 25px;
}

@media (max-width: 450px) {
    .icon {
        width: 159px;
        height: 159px;
    }

    .description {
        color: white;
        font-size: 14px;
    }

    .name {
        font-size: 44px;
        font-weight: 600;
    }

    .sub {
        font-size: 14px;
    }

    .button_sub {
        width: 160px;
        height: 33px;
        font-size: 16px;
        font-weight: 300;
        margin-top: 3px;
    }
}
