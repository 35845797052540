.container {
    background: rgba(26, 26, 26, 0.70);
    backdrop-filter: blur(5px);
    margin-left: 15px;
    margin-right: 15px;
    padding-top: 65px;
    padding-bottom: 79px;
    max-height: calc(100vh - 70px);
    height: calc(100vh - 70px);
    overflow: hidden;
}

@media (max-width: 480px) {
    .container {
        padding-top: 89px;
        margin: 0;
    }
}

.container_content {
    display: flex;
    justify-content: space-between;
}

.item {
    margin-right: 5px;
}

.hidden {
    display: none;
}