.card {
    display: flex;
    position: relative;
    align-items: center;
    margin: 0 auto 25px;

    width: 587px;
}

.container_list {
    position: relative;
    left: -40px;
    text-align: left;
}

@media (max-width: 580px) {
    .container_list {
        left: unset;
        width: fit-content;
        margin: 0 auto;
        text-align: center;
    }
}

.card_title {
    text-shadow: 0px 0px 4px rgba(246, 73, 48, 0.70);
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 30px;
    font-weight: 500;
}

.card_content {
    height: 249px;
    width: 384px;
    position: relative;
}

.item_description {
    font-size: 14px;
    font-weight: 400;
}

@media (max-width: 580px) {
    .card {
        margin: 0 auto;
        max-width: 384px;
        display: block;
    }
    .card_title {
        left: unset;
    }
}

@media (max-width: 480px) {
    .card {
        margin: 0 auto;
        max-width: 340px;
        display: block;
    }

    .card_title {
        top: -50px;
        left: unset;
    }

    .card_content {
        height: 200px;
    }
}

@media (max-width: 320px) {
    .card {
        margin: 0 auto;
        max-width: 260px;
        display: block;
    }

    .card_title {
        top: -50px;
    }
}

.list {
    position: relative;
}

.item_title {
    color: #FFF;
    text-shadow: 0px 0px 4px rgba(246, 73, 48, 0.70);
    font-family: Tektur;
    font-size: 14px;
    font-weight: 600;
    line-height: 88%; /* 12.32px */
    margin-bottom: 4px;
    margin-top: 10px;
}

.description {
    color: #C4C4C4;
    text-align: center;
    font-size: 12px;
    font-weight: 300;
    max-width: 380px;
    margin: 0 auto;
}

@media (max-width: 580px) {
    .description {
        margin-top: 30px;
    }
}