.container {
  position: relative;
  width: 150%;
  left: -25%;

  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: "Tektur", "Times New Roman", Times, cursive;
  font-size: 33px;
  font-weight: 600;
}

.container_inner {
  display: flex;
  overflow-x: hidden;
  position: relative;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
}

.container_left {
  /* transition: left 2s ease-in; */
  animation: shadow_right 0.9s linear;
}

.container_right {
  animation: shadow_left 0.9s linear;
}

@media (max-width: 530px) {
  .container {
    overflow-x: hidden;
  }
}

.container_week {
  width: 100%;
  margin-right: 6%;
  margin-left: 6%;
}

.clean {
  height: 43px;
  text-align: center;
  opacity: 0;
  width: 50vw;
  white-space: nowrap;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Tektur", "Times New Roman", Times, cursive;
  font-size: 33px;
  font-weight: 600;
}

.after {
  text-align: center;
  opacity: 0.8;

  white-space: nowrap;
  background: linear-gradient(90deg, #fff 0%, rgba(255, 255, 255, 0) 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  cursor: pointer;
  font-family: "Tektur", "Times New Roman", Times, cursive;
  font-size: 33px;
  font-weight: 600;
  /* animation: shadow_right 1s linear; */
}

.current {
  text-align: center;
  font-family: "Tektur", "Times New Roman", Times, cursive;
  font-size: 33px;
  font-weight: 600;
  white-space: nowrap;
  transform-origin: center center;
  transform: scale(1);
  scroll-snap-align: center;
}

.before {
  position: relative;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  opacity: 0.8;
  white-space: nowrap;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, #fff 90%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: "Tektur", "Times New Roman", Times, cursive;
  font-size: 33px;
  font-weight: 600;
  cursor: pointer;
}

@keyframes shadow_left {
  from {
    transform: translateX(38vw);
    opacity: 0.5;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes shadow_right {
  from {
    transform: translateX(-38vw);
    opacity: 0.5;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@media (max-width: 530px) {
  @keyframes shadow_left {
    from {
      transform: translateX(50vw);
      opacity: 0.4;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes shadow_right {
    from {
      transform: translateX(-50vw);
      opacity: 0.4;
    }
    to {
      transform: translateX(0);
      opacity: 1;
    }
  }
}
