.container_first {
    min-height: 100vh;
    background: url('https://bp-assets.dnzg.ru/dota2.png');
	background-size: cover;
	background-position: bottom center;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
}

@media (max-width: 460px) {
    .container_first {
        background: url('https://bp-assets.dnzg.ru/start.png');
        background-size: cover;
        background-position: center;
        min-height: 100vh;
        height: 100%;
    }
}

.container_outer {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}

.container_inner {
    width: 100%;
    height: 40%;
}

.container {
    padding-bottom: 61px;
}

.container_title {
    margin-top: 68px;
    margin-bottom: 37px;
}

.title_first {
    width: 300px;
    text-align: center;
    margin: 0 auto;
    font-size: 46px;
    line-height: 34px;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-weight: 600;
    text-transform: uppercase;
}

.title {
    text-align: center;
    font-size: 46px;
    line-height: 34px;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-weight: 600;
    text-transform: uppercase;
}

.container_card {
    display: flex; 
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 28px;
}

@media (max-width: 480px) {
    /* .container_card{
        flex-direction: column;
        justify-content: center;
    } */
}

.card {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 180px;
    height: 180px;
    border-radius: 10px;
    border: 1px solid #727272;
    background-color: rgba(42, 42, 42, 0.70);
    backdrop-filter: blur(5px);

    text-align: center;
    align-content: space-between;
    margin: 0 8px 10px;
}


@media (max-width: 480px) {
    .card{
        border-radius: 18px;
        border: 1.8px solid #727272;
        width: 324px;
        height: 324px;
        margin-top: 29px;
    }
}

.card_title {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-weight: 500;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    font-size: 16px;
    line-height: 13px;
    margin-top: 16px;
}

@media (max-width: 480px) {
    .card_title {
        font-size: 29px;
        line-height: 23.4px;
        width: 100%;
        margin-top: 29px;
    }
}

.card_image {
    position: fixed;
    bottom: 0;
    border-radius: 10px;    
}

.card_image_violet {
    position: fixed;
    bottom: 0;
    left: 0;
    border-radius: 10px;    
}

.card_image_blue {
    position: fixed;
    bottom: 0;
    right: 0;
    border-radius: 10px;    
}

.image_score {
    position: relative;
}

.card_paragraph {
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12px;
    margin-bottom: 11px;
    height: 24px;
}

@media (max-width: 480px) {
    .card_paragraph {
        height: 42px;
        align-items: start;
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 20px;
    }
}

.container_button {
    display: flex;
    justify-content: space-around;
}

.button {
    text-transform: uppercase;
    width: 145px;
    height: 40px;
    margin: 0 auto;
    background-color: rgba(42, 42, 42, 0.9);
    border-radius: 9.8px;
    border: 1px solid #727272;

    color: white;
    font-size: 15px;
    font-weight: 400;
    text-align: center;
    letter-spacing: 1.738px;
}

@media (max-width: 480px) {
    .button {
        width: 258px;
        height: 71px;
        letter-spacing: 3px;
        font-size: 29px;
        margin-bottom: 20px;
    }
}

.description_container {
    text-align: center;
    margin-top: 12px;
    margin-bottom: 30px;
}

.description {
    font-size: 14px;
    font-weight: 300;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0px 0px 4px #000;
}

.link {
    text-decoration: underline;
    font-weight: 300;
    cursor: pointer;
}

@media (max-width: 480px) {
    .description {
        font-size: 16px;
    }
}