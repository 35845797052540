.title {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    text-align: center;
    font-size: 33px;
    font-weight: 600;
}

.container {
    width: 332px;
    margin: 0 auto;
}

.items_container {
    max-width: 328px;
}

.item_container {
    border-bottom: 1px solid rgba(114, 114, 114, 1);
    display: flex;
    align-items: center;

    cursor: pointer;
}

@media (max-width: 480px) {
    .items_container {
        display: flex;
        flex-direction: column;
        max-width: 320px;
        margin: 0 auto;
    }
}

@media (max-width: 350px) {
    .container {
        max-width: 290px;
    }
}

.item_title {
    display: flex;
    font-size: 14px;
    align-items: center;
}

.item_paragraph {
    font-weight: 300;
    font-size: 14px;
    margin-left: 37px;
}

.button_link {
    margin-top: 16px;
    margin-bottom: 9px;
    width: 120px;
    height: 21px;
    font-size: 13px;
    font-weight: 300;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5.611px;
    border: 0.573px solid #727272;
    background: rgba(42, 42, 42, 0.70);
}

.button_link:hover {
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.50), 0px 0px 4px 0px #FFF, 0px 0px 4px 0px rgba(255, 255, 255, 0.50);
}