.modal_background {
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal_container {
  text-align: start;
  font-family: Rubik;
  font-size: 14px;
  padding: 19px 22px;
  border-radius: 10px;
  color: white;
  min-width: 343px;
  background-color: #2a2a2a;
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  gap: 20px;
  position: relative;
}

.wrapper_info {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 194px;
  position: relative;
  z-index: 10;
}

.wrapper_image {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  border: 1px solid #FFF;
}

.modal_title {
  font-size: 33px;
  font-family: 'Tektur', 'Times New Roman', Times, cursive;
  width: 194px;
  color: #fff;
  text-shadow: 0px 0px 4px rgba(246, 73, 48, 0.7);
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
}

.modal_label {
  color: #fff;
  font-size: 14px;
  font-weight: 300;
  text-align: left;
}

.modal_label_highlighted {
  color: #ffc700;
  font-family: Rubik;
  font-size: 14px;
  font-weight: 400;
}

.button {
  color: #FFC700;
  font-size: 14px;
  font-weight: 300;
  text-decoration-line: underline;
}

.container_second {
  display: flex;
  flex-direction: column;
}


.info_container {
  width: 184px;
}

.wrapper_close {
  width: 25px;
  height: 25px;
  padding: 6px;
  cursor: pointer;
  margin-left: auto;
  z-index: 10;
}

.list {
  width: fit-content;
}

@media (max-width: 480px) {
  .list {
    margin: 0 auto;
  }
}

.image_game {
  position: absolute;
  left: 56%;
  top: 15%;
  width: 140px;
  height: 165px;
  z-index: 5;
}

.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media (max-width: 480px) {
  .wrapper_info {
    text-align: center;
    margin: 0 auto;
    top: 35%;
  }

  .image_game {
    position: absolute;
    top: 30vh;
    left: -130px;
    width: 294px;
    height: 344px;
  }
  
.wrapper_close {
  width: 25px;
  height: 25px;
  cursor: pointer;
  margin-left: auto;
  margin-right: 45px;
  padding: 0;
}
}