.container {
    background-color: rgba(9, 9, 9, 0.70);
    color: white;
    margin: 15px;
    height: calc(100vh - 30px);
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 480px) {
    .container {
        margin: 0;
        height: 100vh;
    }
}

.image_container {
    width: 100%;
    display: flex;
    justify-content: center;
}


.image {
    width: 134px;
    height: 134px;
    margin: 0 auto;
}

.title {
    text-align: center;
    width: 100%;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.description {
    margin-top: 8px;
    text-align: center;
    width: 100%;
    color: #FFF;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.button_container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.button {
    width: 220px;
    height: 40px;
    margin-top: 17px;
    border-radius: 6.759px;
    border: 0.69px solid #727272;
    background: rgba(42, 42, 42, 0.70);
}