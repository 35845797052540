.container {
    margin-left: 15px;
    margin-right: 15px;
    position: relative;
    max-width: 100vw;
}

.page {
    padding-top: 70px;
    background: rgba(26, 26, 26, 0.70);
    backdrop-filter: blur(5px);
}

@media (max-width: 480px) {
    .container {
        margin-left: 0;
        margin-right: 0;
        overflow-x: hidden;
    }
    .page {
        padding-top: 105px;
    }
}
