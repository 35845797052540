.item {
    display: flex;
    margin-left: 16px;
    position: relative;
    cursor: pointer;
}

.icon_q {
    margin-left: 2px;
}

.alert_container {
    position:absolute;
    min-width: 134px;
    padding: 9px;
    background-color: rgba(42, 42, 42, 0.703);
    border-radius: 4px;
    backdrop-filter: blur(5px);
    left: -150px;
    bottom: 10px;
    z-index: 1000;
}

@media (max-width: 480px) {
    .alert_container {
        left: -170px;
    }
}

.container_alert {
    display: flex;
}

.alert_text {
    font-size: 12px;
    font-weight: 300;
}

.text {
 text-align: left;
 width: 134px;
 top: -50px;
}

.mobile_item {
    display: flex;
    width: 28px;
    align-items: center;
}

.image {
    display: flex;
    align-items: center;
}

.triangle {
    display: block;
    height: 20px;
    width: 10px;
    background-color: rgba(42, 42, 42, 0.70);
    border: inherit;
    position: absolute;
    bottom: 0px;
    left: 10px;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-64deg);
    border-radius: 0 0 0 0.25em;
    backdrop-filter: blur(5px);
}

@media (max-width: 480px) {
    .triangle {
        left: -10px;
    }
}

.title {
    font-weight: 500;
    font-size: 10px;
}

.popup {
    position: relative;
  }
  
.mobile_item:hover .wrapper {
    display: block;
    opacity: 1;
}

.popup:hover .wrapper {
    display: block;
    opacity: 1;
}


.wrapper {
    position: absolute;
    display: none;
    bottom: 100%;
    z-index: 10;
    opacity: 0;
    transition: opacity 0.15s ease-in-out;
  }