.container {
    width: calc(100% - 40px);
    margin: 20px;
}

.container_inner {
    max-width: 452px;
    min-height: 261px;
    margin: 0 auto;
    border-radius: 10px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    padding: 38px 30px 21px;
}

.title {
    max-width: 400px;
    text-align: center;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 24px;
    font-weight: 500;
    margin: 0 auto;
    margin-bottom: 27px;
}

.description {
    width: 100%;
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    line-height: normal;
}

.time {
    color: #FFF;
    text-align: center;
    text-shadow: 0px 0px 4px rgba(246, 73, 48, 0.70);
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 18px auto 24px;
}

.button {
    font-size: 13.742px;
    font-weight: 300;
    border-radius: 5.611px;
    border: 0.573px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    max-width: 120px;
    height: 21px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    cursor: pointer;
    text-decoration: none;
}