.container {
    border-radius: 10px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    position: relative;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 20px;
    padding-bottom: 20px;
    max-width: 486px;
    margin: 20px auto;
    width: calc(100% - 40px)
}

.container_inner {
    position: relative;
    z-index: 10;
    max-width: 309px;
}

.title {
    text-shadow: 0px 0px 4px rgba(246, 73, 48, 0.70);
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 30px;
    font-weight: 500;
    margin-bottom: 6px;
}

.text {
    font-weight: 300;
    font-size: 14px;
}

.date {
    color: #FFC700;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 14px;
    font-weight: 500;
}

.button {
    width: fit-content;
    margin-top: 20px;
    font-size: 14px;
    font-weight: 300;
    border-radius: 5.611px;
    border: 0.573px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    display: flex;
    padding: 4px 29px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}

.button_dis {
    width: fit-content;
    margin-top: 24px;
    font-size: 14px;
    font-weight: 300;
    border-radius: 5.611px;
    border: 0.573px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    display: flex;
    padding: 4px 29px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    text-decoration: none;
}

.button:hover {
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.50), 0px 0px 4px 0px #FFF, 0px 0px 4px 0px rgba(255, 255, 255, 0.50);
}

.image_container {
    position: absolute;
    right: 0px;
    bottom: -4px;
    z-index: 1;
    border-radius: 10px;
}

.image {
    border-radius: 10px;
}

@media (max-width: 480px) {
    .image_container {
        right: 0px;
        bottom: -4px;
    }

}

@media (max-width: 413px) {
    .image_container {
        bottom: 0;
        height: 206px;
    }
    
    .image {
        height: inherit;
    }
}