.modal_background {
    position: fixed;
    z-index: 6;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;
    background-color: rgba(0, 0, 0, 0.5);
}

.container {
    position:  relative;
    text-align: flex-start;
    font-family: Rubik;
    font-size: 14px;
    padding: 19px 22px;
    border-radius: 10px;
    color: white;
    background-color: #2a2a2a;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    overflow: hidden;
    gap: 20px;
    width: 343px;
}

.modal_title {
    font-size: 33px;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    color: #fff;
    text-shadow: 0px 0px 4px rgba(246, 73, 48, 0.7);
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    margin-bottom: 18px;
}

.paragraph {
    width: 230px;
    font-size: 14px;
    font-weight: 300;
    user-select:all;
}

.container_link {
    display: flex;
    align-items: center;
    gap: 5px;
    position: relative;
    justify-content: center;
}

.hint {
    position: absolute;
    background-color: rgba(42, 42, 42, 0.70);
    backdrop-filter: blur(5px);
    border: 1px solid #727272;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 300;
    padding: 3px 7px;
    top: -150%;
    right: 10px;
}

.text {
    width: 175px;
    height: 25px;
    border-radius: 5px;
    border: 1px solid #727272;
    
    padding: 4px 9px 5px 12px;
}
  
.text_link {
    color: #727272;
    font-size: 13.742px;
    font-weight: 300;
    overflow: hidden;
    user-select:all;
}

.button {
    width: 120px;
    height: 25px;
    font-size: 13.742px;
    font-weight: 300;
}

.button_dis {
    width: 120px;
    height: 25px;
    font-size: 13.742px;
    font-weight: 300;
    color: #727272;
}

.wrapper_close {
    position: absolute;
    width: 13px;
    height: 13px;
    right: 20px;
    top: 20px;
    cursor: pointer;
}

.modal_container {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.modal_wrapper {
    width: 300px;
    text-align: center;
    position: relative;
}

.paragraph_m {
    width: 211px;
    font-size: 14px;
    font-weight: 300;
    margin: 0 auto;
    margin-bottom: 28px;
    user-select:all;

}

.wrapper_close_m {
    position: absolute;
    width: 20px;
    height: 20px;
    right: -20px;
    top: -203px;
    cursor: pointer;
}