.container {
    position: relative;
    width: 30px;
}

.container_last {
    position: relative;

}

.container_active {
    position: relative;
    width: 44px;
}

.container_last_active {
    position: relative;
}


.day {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 15px;
    font-weight: 600;

    position: absolute;
    top: 3px;
    left: 13px;
    background-color: rgba(91, 129, 140, 1);
    color: transparent;
    text-shadow: 0px 0px 1px black;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    cursor: pointer;
}

.day_last {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 15px;
    font-weight: 600;

    position: absolute;
    top: 3px;
    left: 30px;
    background-color: rgba(91, 129, 140, 1);
    color: transparent;
    text-shadow: 0px 0px 1px black;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;  
    cursor: pointer;  
}

.day_active {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    position: absolute;
    font-weight: 600;
    font-size: 20px;
    top: 6px;
    left: 18px;
    background-color: rgba(91, 129, 140, 1);
    color: transparent;
    text-shadow: 0px 0px 1px black;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
}

.day_last_active {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    position: absolute;
    font-weight: 600;
    font-size: 20px;
    top: 6px;
    left: 40px;
    background-color: rgba(91, 129, 140, 1);
    color: transparent;
    text-shadow: 0px 0px 1px black;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text; 
}
