.container_item {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 14px;    
    margin-top: 9px;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    color:#FFF;
}

.container_item_first {
    position: relative;
    display: flex;
    justify-content: space-between;
    font-size: 14px;    
    margin-top: 9px;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    color: #FFC700;
}

.item_inner {
    display: flex;
    color: inherit;
}

.item_name {
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-weight: 500;
    font-size: 14px;
    color: inherit;
}

@media (max-width: 480px) {
    .item_name {
        font-size: 14px;
    }
}

.total {
    text-align: center;
    font-weight: 300;
    width: 65px;
    color: inherit;
}

.item_erv {
    width: 88px;
    text-align: center;
    font-weight: 300;
    margin-left: 10px;
    color: inherit;
}

.item_sub {
    width: 77px;
    text-align: center;
    margin-left: 16px;
    font-weight: 300;
    color: inherit;
}

.item_sub_delta {
    width: 70px;
    text-align: center;
    margin-left: 16px;
    margin-right: 10px;
    font-weight: 300;
    color: inherit;
}

@media (max-width: 480px) {
    .total {
        width: 48px;
        margin: 0;
        font-size: 14px;
    }
    .item_erv {
        width: 46px;
        margin: 0;
        font-size: 14px;

    }

    .item_sub  {
        width: 37px;
        margin: 0;
        font-size: 14px;

    }

    .item_sub_delta {
        width: 44px;
        margin: 0 11px 0 0;
        font-size: 14px;

    }
}

.container_buttons {
    position: absolute;
    left: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button_sub {
    font-size: 10px;
    border-radius: 5px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 1);
    padding-top: 3px;
    padding-bottom: 3px;
    font-weight: 300;
    text-decoration: none;
    padding: 3px 7px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button_hub {
    font-size: 10px;
    border-radius: 5px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 1);
    margin-left: 4px;

    font-weight: 300;
    text-decoration: none;
    padding: 3px 7px;

    display: flex;
    align-items: center;
    justify-content: center;
}

.button_sub:hover {
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.50), 0px 0px 4px 0px #FFF, 0px 0px 4px 0px rgba(255, 255, 255, 0.50);
}

.button_hub:hover {
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.50), 0px 0px 4px 0px #FFF, 0px 0px 4px 0px rgba(255, 255, 255, 0.50);
}

.button_mobile {
    position: absolute;
    bottom: 100px;
}
.button_sub_m {
    font-size: 12px;
    height: 23px;
    width: 98px;
    border-radius: 5px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);

}

.button_hub_m {
    font-size: 12px;
    width: 108px;
    height: 23px;
    border-radius: 5px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    margin-left: 4px;
}
