.popup {
  position: relative;
}

.popup:hover .wrapper {
  display: block;
  opacity: 1;
}

.alert_container {
  font-size: 12px;
  padding: 12px;
  border-radius: 1px;
  color: white;
  background-color: rgb(42, 42, 42);
  display: flex;
  gap: 12px;
  align-items: center;
  width: 170px;
}

.wrapper_image {
  width: 24px;
  height: 24px;
}

.wrapper {
  display: none;
  position: absolute;
  bottom: 100%;
  z-index: 10;
  opacity: 0;
  transition: opacity 0.15s ease-in-out;
}

.triangle {
  margin-left: 14px;
  width: 0;
  height: 0;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-top: 9px solid rgb(42, 42, 42);
}

@media (max-width: 480px) {
  .triangle {
    margin-left: 50%;
  }
}
