.wrapper_main {
    padding-bottom: 120px;
}

.main {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
}

.container {
    position: relative;
    display: flex;
    max-width: 489px;
    min-height: 165px;
    border-radius: 10px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    padding-top: 13px;
    padding-left: 20px;
    padding-bottom: 19px;
    margin: 68px auto 20px;
}
.content {
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

@media (max-width: 520px) {
    .container {
        max-width: 489px;
    }
}

@media (max-width: 480px) {
    .container {
        max-width: 489px;
        width: auto;
        min-height: 205px;
    }
}

.image_container {
    position: absolute;
    right: -50px;
    top: -100px;
    height: 277px;
    width: 362px;
    z-index: 2;
    transform: translate3d(0,0,0);
}

.image {
    height: 277px;
}

@media (max-width: 480px) {
    .image_container {
        left: 40px;
    }
}

.title {
    font-family: 'Tektur', 'Times New Roman', Times, cursive ;
    text-shadow: 0px 0px 4px rgba(246, 73, 48, 0.70);
    font-size: 30px;
    font-weight: 500;
    line-height: 78%;
    margin-bottom: 16px;
}
@media (max-width: 520px) {
    .title{
        max-width: 250px;
    }
}


@media (max-width: 480px) {
    .title {
        max-width: 184px;
        width: auto;
    }
}
.description {
    font-size: 12px;
    font-weight: 300;
    max-width: 250px;
    margin-bottom: 13px;
}

@media (max-width: 520px) {
    .description {
        max-width: 60%;
    }
}

@media (max-width: 480px) {
    .description {
        max-width: 200px;
        width: auto;
        font-size: 13px;
    }
}


.footer {
    display: flex;
    align-items: center;
    position: relative;
    max-width: 300px;
    min-width: 240px;
    justify-content: space-between;
}

.button {
    font-size: 13px;
    font-weight: 300;
    line-height: normal;
    border-radius: 6px;
    border: 0.6px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 9px;
    margin-right: 5px;
}

.button:hover {
    box-shadow: 0px 0px 4px 0px rgba(255, 255, 255, 0.50), 0px 0px 4px 0px #FFF, 0px 0px 4px 0px rgba(255, 255, 255, 0.50);
}

.add {
    font-size: 13px;
    font-weight: 300;
    text-decoration: underline;
    cursor: pointer;
}

.response {
    text-align: center;
    font-size: 13px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}


@media (max-width: 480px) {
    .add {
        font-size: 12px;
    }
}

.time {
    font-size: 14px;
    font-weight: 500;
    color: #727272;
}

@media (max-width: 480px) {
    .time {
        min-width: 220px;
        margin-top: 12px;
    }

    .footer {
        flex-wrap: wrap;
    }    
}

.about {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    margin: 30px auto 0;
}

.about_cp {
    color: #FFC700;
    font-family: 'Tektur', 'Times New Roman', Times, cursive ;
    font-size: 14px;
    font-weight: 500;
}

.daily_tasks {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

@media (max-width: 550px) {
    .daily_tasks {
        grid-template-columns: 1fr 1fr;
    }    
}

@media (max-width: 390px) {
    .daily_tasks {
        display: block;
    }    
}

.bonus_tasks {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: minmax(90px, auto);
    text-align: left;
}

@media (max-width: 520px) {
    .bonus_tasks {
        display: block;
    }
}

.container_bonus {
    max-width: 520px;
    margin: 0 auto;
    text-align: center;
}

.title_bonus {
    font-size: 33px;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    margin-top: 30px;
    font-weight: 600;
    margin-bottom: 35px;
}

@media (max-width: 480px) {
    .title_bonus {
        margin-top: 54px;
        margin-bottom: 32px;
    }
}

.card_container_bonus {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    text-align: left;
}

@media (max-width: 480px) {
   .card_container_bonus {
        display: block;
   }
}

.container_daily_tasks {
    margin: 0 auto;
    text-align: center;
}

.title_daily_tasks {
    font-size: 33px;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    margin-top: 30px;
    font-weight: 600;
    margin-bottom: 35px;
}

.container_daily_card {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: left;
}

.alert_container {
    font-size: 12px;
    padding: 12px;
    border-radius: 1px;
    background-color: rgb(42, 42, 42);
    display: flex;
    gap: 12px;
    align-items: center;
    width: 200px;
  }
  
  .wrapper_image {
    width: 24px;
    height: 24px;
  }
  
  .wrapper {
    position: absolute;
    bottom: 100%;
    z-index: 10;
    transition: opacity 0.15s ease-in-out;
  }
  
  .triangle {
    margin-left: 14px;
    width: 0;
    height: 0;
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid rgb(42, 42, 42);
  }
  

  .container_notasks {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: auto auto;
  }