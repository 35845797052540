.container {
    position: fixed;
    bottom: 0;
    height: 116px;
    width: 100%;
    display: flex;
    padding-left: 49px;
    align-items: center;
    justify-content: flex-start;
    padding-top: 20px;
    padding-right: 15px;
}

@media (max-width: 480px) {
    .container {
        padding-left: 37px;
        padding-right: 34px;
    }
    
}

.user_container {
    display: flex;
    position: relative;
    align-items: center;
}

.photo {
    position: fixed;
    width: 45px;
    height: 46px;
    border-radius: 50%;
    border: 1px solid rgba(114, 114, 114, 1);
    background-color: #D9D9D9;
}

.user_name_container {
    border-radius: 5px;
    border: 1px solid rgba(114, 114, 114, 1);
    background: rgba(42, 42, 42, 0.70);
    min-width: 150px;
    height: fit-content;
    padding: 6px 6px 7px 25px;
    margin-left: 30px;
    margin-right: 22px;
}

.user_name {
    font-size: 12px;
    font-weight: 500;
}

.team {
    color: rgba(255, 199, 0, 1);
    font-size: 12px;
    font-weight: 300;
    margin-top: 1px;
    text-decoration: none;
    display: block;
}

.value {
    color: rgba(255, 199, 0, 1);
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}
.score, 
.max_score {
    font-family: inherit;
    font-weight: inherit;
    font-size: inherit;
}

.score {
    margin-right: 5px;
    margin-left: 4px;
}

.max_score {
    color: #727272;
}

.footer {
    width: 100%;
}

.score_container {
    display: flex;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-weight: 500;
    font-size: 12px;
}

.total_progress {
    margin-top: 4px;
    width: 100%;
    border-radius: 10px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    display: flex;
    align-items: center;
    padding: 0;
}

.progress {
    max-width: 100%;
    border-radius: 10px;
    background: #FFC700;
    height: 8px
}

.alert_container {
    position:absolute;
    min-width: 150px;
    padding: 9px;
    background-color: rgba(42, 42, 42, 1);
    border-radius: 4px;
    bottom: 40px;
    left: 30px;
    z-index: 1000;
}

.alert_text {
    font-size: 10px;
    font-weight: 300;
}

.triangle {
    display: block;
    height: 20px;
    width: 10px;
    background-color: rgba(42, 42, 42, 1);
    border: inherit;
    position: absolute;
    bottom: 30px;
    left: 40px;
    clip-path: polygon(0% 0%, 100% 100%, 0% 100%);
    transform: rotate(-64deg);
    border-radius: 0 0 0 0.25em;
}