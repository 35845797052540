.name {
    font-size: 14px;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-weight: 500;
    width: 250px;
    margin: 10px auto;
    text-align: center;
}

.button_mobile {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
}
.button_sub_m {
    font-size: 12px;
    height: 23px;
    width: 98px;
    border-radius: 5px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    font-weight: 300;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.button_hub_m {
    font-size: 12px;
    width: 108px;
    height: 23px;
    border-radius: 5px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    margin-left: 4px;
    font-weight: 300;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
}