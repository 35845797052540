.container {
    max-width: 520px;
    margin: 0 auto;
    text-align: center;
}

.title {
    font-size: 33px;
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    margin-top: 30px;
    font-weight: 600;
    margin-bottom: 35px;
}

@media (max-width: 520px) {
    .title {
        margin-top: 54px;
        margin-bottom: 32px;
    }
}

.card_container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    text-align: left;
}

@media (max-width: 520px) {
   .card_container {
        display: block;
   }
}