.modal_background {
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal_container {
  font-family: 'Tektur', 'Times New Roman', Times, cursive;
  font-size: 14px;
  padding: 42px 45px;
  border-radius: 10px;
  color: white;
  background-color: rgb(42, 42, 42);
  display: flex;
  flex-direction: column;
  gap: 27px;
  max-height: calc(100vh - 20px);
  overflow-y: auto;
}

.wrapper_image {
  width: 37px;
  height: 37px;
  padding: 12px;
  cursor: pointer;
}

.modal_title {
  font-size: 33px;
  font-family: 'Tektur', 'Times New Roman', Times, cursive;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/* MOBILE */

.container {
  position: fixed;
  z-index: 6;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.image {
  position: fixed;
  right: 30px;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.title {
  font-size: 24px;
  font-family: 'Tektur', 'Times New Roman', Times, cursive;
  font-weight: 600;
  margin-bottom: 45px;
  margin-top: 40px;
  margin-left: 32px;
}

.paragraph {
  font-size: 14px;
  font-weight: 300;
  margin-left: 32px;
}