.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    font-size: 10px;
    font-weight: 500;
    max-width: 600px;
    height: 16px;
    background-color: rgba(42, 42, 42, 0.5);
    padding-right: 10px;
    padding-left: 11px;
}

@media (max-width: 480px) {
    .header {
        font-size: 14px;
        padding-left: 26px;
        height: 20px;
    }
}

.header_inside {
    display: flex;
}

.header_team {
    font-weight: 500;
}

.list {
    max-width: 600px;
    padding-left: 10px;
    margin: 0 auto;
}

@media (max-width: 480px) {
    .list {
        padding-left: 26px;
    }
}

.footer {
    display: flex;
    justify-content: space-between;
    max-width: 519px;
    padding-left: 10px;
    margin: 36px auto 0;
}

.wrapper_button {
    position: relative;
}

.footer_button {
    position: relative;
    text-decoration: underline;
    color: #727272;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
}

.footer_button_active {
    position: relative;
    text-decoration: underline;
    color: #ffc700;
    font-size: 14px;
    font-weight: 300;
    cursor: pointer;
}

.number {
    font-size: 14px;
    font-weight: 500;
}

@media (max-width: 480px) {
    .footer {
        flex-direction: column-reverse;
        position: relative;
    }

    .number {
        text-align: center;
    }
    .footer_button {
        position: relative;
        text-align: right;
        padding-right: 39px;
    }

    .footer_button_active {
        position: relative;
        text-align: right;
        padding-right: 39px;
    }

    .wrapper_button {
        bottom: -85px;
        display: flex;
        justify-content: flex-end;
    }
}

.overflow {
    height: 40vh;
    overflow: auto;
}

.overflow::-webkit-scrollbar {
    width: 5px;
}

.overflow::-webkit-scrollbar-track {
    background: #72727233;
    border-radius: 5px;
}

.overflow::-webkit-scrollbar-thumb {
    background: #ffffff66;
    border-radius: 5px;
}

.scroll_container {
    position: absolute;
    max-width: 600px;
    width: 100%;
    max-height: 40vh;
    height: 100%;
}