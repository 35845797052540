.container {
    max-width: 452.41px;
    height: 156.694px;
    border-radius: 10px;
    border: 1px solid #727272;
    background: rgba(42, 42, 42, 0.70);
    position: relative;
    padding: 19px 30px 30px 0;
    display: flex;
    justify-content: flex-end;
    margin: 0 auto;
}

@media (max-width: 432px) {
    .container {
        padding: 24px 0;
    }
}
@media (max-width: 370px) {
    .container {
        padding: 24px 0;
    }
}

.title {
    text-align: center;
    text-shadow: 0px 0px 4px rgba(246, 73, 48, 0.70);
    font-family: 'Tektur', 'Times New Roman', Times, cursive;
    font-size: 24px;
    font-weight: 500;
    width: 309px;
    margin-bottom: 12px;
}

.text {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    width: 309px;
}

.link {
    color: #FFC700;
    font-size: 14px;
    font-weight: 400;
    text-decoration-line: underline;
}

.img_container {
    position: absolute;
    left: 17%;
    top: 22%;
}

@media (max-width: 532px) {
    .img_container {
        left: 5%;
    }
}

@media (max-width: 432px) {
    .img_container {
        left: -5%;
    }
}

@media (max-width: 365px) {
    .img_container {
        display: none;
    }
}

.image {
    width: 50px;
    height: 96px;
    transform: rotate(-20.716deg);
}